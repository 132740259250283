<template>
    <div class="switch_wpr">
        <div class="setting_wpr">
            <label for="filter_name" class="switch_option capsule_btn border-0 mb-1">
                <h5>By Name <span>Includes First Name, Last Name</span></h5>
                <input type="checkbox" :true-value="1" :false-value="0" id="filter_name" v-model="filter_name" hidden>
                <div><span></span></div>
            </label>
            <div class="mb-4" v-if="filter_name">
                <div class="form_grp or_option pt-2">
                    <div class="group_item">
                        <label class="input_label">First Name</label>
                        <div class="field_wpr">
                            <multiselect v-model="filters.first_name.filter" v-bind="firstName"></multiselect>
                        </div>
                    </div>
                    <div class="group_item" v-if="filters.first_name.filter && filters.first_name.filter.length">
                        <div class="field_wpr">
                            <input type="text" placeholder="Chris" v-model="filters.first_name.keyword" >
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Last Name</label>
                        <div class="field_wpr">
                            <multiselect v-model="filters.last_name.filter" v-bind="lastName"></multiselect>
                        </div>
                    </div>
                    <div class="group_item" v-if="filters.last_name.filter && filters.last_name.filter.length">
                        <div class="field_wpr">
                            <input type="text" placeholder="Damon" v-model="filters.last_name.keyword">
                        </div>
                    </div>
                </div>
            </div>
            <label for="filter_email" class="switch_option capsule_btn border-0 mb-1">
                <h5>By Email <span>Includes Email, Email Status</span></h5>
                <input type="checkbox" :true-value="1" :false-value="0" id="filter_email" v-model="filter_email" hidden>
                <div><span></span></div>
            </label>
            <div class="mb-4" v-if="filter_email">
                <div class="form_grp pt-2">
                    <div class="group_item">
                        <label class="input_label">Email</label>
                        <div class="field_wpr">
                            <multiselect v-model="filters.email.filter" v-bind="email"></multiselect>
                        </div>
                    </div>
                    <div class="group_item" v-if="filters.email.filter && filters.email.filter.length">
                        <div class="field_wpr has_prefix">
                            <input type="text" placeholder="brian.demo@onboardme.io" v-model="filters.date_joined.keyword" >
                            <span class="prefix"><i class="fas fa-envelope"></i></span>
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Email Status</label>
                        <div class="field_wpr">
                            <multiselect v-model="filters.email_status" v-bind="emailStatus" ></multiselect>
                        </div>
                    </div>
                </div>
            </div>
            <label for="filter_phone" class="switch_option capsule_btn border-0 mb-1">
                <h5>By Phone <span>Includes Phone, Phone Status</span></h5>
                <input type="checkbox" :true-value="1" :false-value="0" id="filter_phone" v-model="filter_phone" hidden>
                <div><span></span></div>
            </label>
            <div class="mb-4" v-if="filter_phone">
                <div class="form_grp pt-2">
                    <div class="group_item">
                        <label class="input_label">Phone</label>
                        <div class="field_wpr">
                            <multiselect v-model="filters.phone.filter" v-bind="phone"></multiselect>
                        </div>
                    </div>
                    <div class="group_item" v-if="filters.phone.filter && filters.phone.filter.length">
                        <div class="field_wpr">
                            <input type="text" v-model="filters.phone.keyword" placeholder="+18433439321" />
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Phone Status</label>
                        <div class="field_wpr">
                            <multiselect v-model="filters.phone_status" v-bind="phoneStatus" ></multiselect>
                        </div>
                    </div>
                </div>
            </div>
            <label for="filter_others" class="switch_option capsule_btn border-0 mb-1">
                <h5>By Others <span>Includes Date Joined, Tags, Temperature, Status, Last Action, Flagged, Gender <!-- Masterkey --></span></h5>
                <input type="checkbox" :true-value="1" :false-value="0" id="filter_others" v-model="filter_others" hidden>
                <div><span></span></div>
            </label>
            <div class="mb-4" v-if="filter_others">
                <div class="form_grp pt-2">
                    <div class="group_item">
                        <label class="input_label">Date Joined</label>
                        <div class="field_wpr">
                            <multiselect v-model="filters.date_joined.filter" v-bind="dateJoined"></multiselect>
                        </div>
                    </div>
                    <div class="group_item" v-if="filters.date_joined.filter && filters.date_joined.filter.length">
                        <div class="field_wpr">
                            <datepicker v-model="filters.date_joined.keyword" autoApply placeholder="MM/DD/YYYY" format="MM/dd/yyyy"></datepicker>
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Tags</label>
                        <div class="field_wpr">
                            <multiselect
                                v-model="filters.tags"
                                :groups="true"
                                :searchable="true"
                                :options="tagsGroup"
                                value-prop="id"
                                label="name"
                                placeholder="Select tag"
                                :group-select="false"
                                group-label="type"
                                group-options="tags"
                                :group-hide-empty="true"
                                mode="tags"
                            >
                                <template v-slot:grouplabel="{ group }">
                                    <span :class="group.type.replace(' ', '-').toLowerCase()">{{ group.type }}</span>
                                </template>
                                <template v-slot:option="{ option }">
                                    <span>{{ option.name }}</span>
                                </template>
                                <template v-slot:tag="{ option, handleTagRemove, disabled }">
                                    <div class="multiselect-tag" :class="option.type.replace(' ', '-').toLowerCase()">
                                    {{ option.name }}
                                    <span
                                        v-if="!disabled"
                                        class="multiselect-tag-remove"
                                        @mousedown.prevent="handleTagRemove(option, $event)"
                                    >
                                        <span class="multiselect-tag-remove-icon"></span>
                                    </span>
                                    </div>
                                </template>
                            </multiselect>
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Temperature</label>
                        <div class="field_wpr">
                            <multiselect v-model="filters.temperature" v-bind="temperature" ></multiselect>
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Segment</label>
                        <div class="field_wpr">
                            <multiselect
                                v-model="filters.status"
                                :groups="true"
                                :searchable="true"
                                :options="statusesGroup"
                                valueProp="value"
                                label="title"
                                placeholder="Select segment"
                                :group-select="false"
                                group-label="type"
                                group-options="items"
                                :group-hide-empty="true"
                                :loading="statusTabsLoader"
                            >
                                <template v-slot:grouplabel="{ group }">
                                    <span class="status-tabs-header">{{ group.type }}</span>
                                </template>
                                <template v-slot:option="{ option }">
                                    <span>{{ option.title }}</span>
                            </template>
                            </multiselect>
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Last Action</label>
                        <div class="field_wpr">
                            <multiselect v-model="filters.last_action" v-bind="lastAction"></multiselect>
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Flagged</label>
                        <div class="field_wpr">
                            <multiselect v-model="filters.flagged" v-bind="flagged"></multiselect>
                        </div>
                    </div>
                </div>
                <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Gender</label>
                        <div class="field_wpr">
                            <multiselect
                            v-model="filters.gender"
                            v-bind="gender"
                            placeholder="Select gender"
                            ></multiselect>
                        </div>
                    </div>
                </div>
                <!-- <div class="form_grp">
                    <div class="group_item">
                        <label class="input_label">Masterkey</label>
                        <div class="field_wpr">
                            <multiselect v-model="filters.masterkey" v-bind="masterkey"></multiselect>
                        </div>
                    </div>
                </div> -->
            </div>
            <label v-if="1 == 0" for="filter_alerts" class="switch_option capsule_btn border-0 mb-1">
                <h5>Has alerts <span>Includes All Alerts</span></h5>
                <input type="checkbox" :true-value="1" :false-value="0" id="filter_alerts" v-model="filter_alerts" hidden>
                <div><span></span></div>
            </label>
            <div class="mb-4" v-if="filter_alerts">
                <label for="alert_unsubscribed" class="switch_option capsule_btn border-0 m-0">
                    <h5>Has unsubscribed</h5>
                    <input type="checkbox" :true-value="1" :false-value="0" id="alert_unsubscribed" v-model="alert_unsubscribed" hidden>
                    <div><span></span></div>
                </label>
                <label for="alert_bounced" class="switch_option capsule_btn border-0 m-0">
                    <h5>Bounced</h5>
                    <input type="checkbox" :true-value="1" :false-value="0" id="alert_bounced" v-model="alert_bounced" hidden>
                    <div><span></span></div>
                </label>
                <label for="alert_spam" class="switch_option capsule_btn border-0 m-0">
                    <h5>Spam complaint</h5>
                    <input type="checkbox" :true-value="1" :false-value="0" id="alert_spam" v-model="alert_spam" hidden>
                    <div><span></span></div>
                </label>
                <label for="alert_email_disabled" class="switch_option capsule_btn border-0 m-0">
                    <h5>Email disabled</h5>
                    <input type="checkbox" :true-value="1" :false-value="0" id="alert_email_disabled" v-model="alert_email_disabled" hidden>
                    <div><span></span></div>
                </label>
                <label for="alert_broadcasts_disabled" class="switch_option capsule_btn border-0 m-0">
                    <h5>Broadcasts disabled</h5>
                    <input type="checkbox" :true-value="1" :false-value="0" id="alert_broadcasts_disabled" v-model="alert_broadcasts_disabled" hidden>
                    <div><span></span></div>
                </label>
                <label for="alert_sms_disabled" class="switch_option capsule_btn border-0 m-0">
                    <h5>SMS disabled</h5>
                    <input type="checkbox" :true-value="1" :false-value="0" id="alert_sms_disabled" v-model="alert_sms_disabled" hidden>
                    <div><span></span></div>
                </label>
                <label for="alert_birthdays_disabled" class="switch_option capsule_btn border-0 m-0">
                    <h5>Birthdays disabled</h5>
                    <input type="checkbox" :true-value="1" :false-value="0" id="alert_birthdays_disabled" v-model="alert_birthdays_disabled" hidden>
                    <div><span></span></div>
                </label>
                <label for="alert_unconfirmed_email" class="switch_option capsule_btn border-0 m-0">
                    <h5>Unconfirmed email</h5>
                    <input type="checkbox" :true-value="1" :false-value="0" id="alert_unconfirmed_email" v-model="alert_unconfirmed_email" hidden>
                    <div><span></span></div>
                </label>
                <label for="alert_invalid_email" class="switch_option capsule_btn border-0 m-0">
                    <h5>Invalid email</h5>
                    <input type="checkbox" :true-value="1" :false-value="0" id="alert_invalid_email" v-model="alert_invalid_email" hidden>
                    <div><span></span></div>
                </label>
                <label for="alert_missing_email" class="switch_option capsule_btn border-0 m-0">
                    <h5>Missing email</h5>
                    <input type="checkbox" :true-value="1" :false-value="0" id="alert_missing_email" v-model="alert_missing_email" hidden>
                    <div><span></span></div>
                </label>
                <label for="alert_missing_phone" class="switch_option capsule_btn border-0 m-0">
                    <h5>Missing phone</h5>
                    <input type="checkbox" :true-value="1" :false-value="0" id="alert_missing_phone" v-model="alert_missing_phone" hidden>
                    <div><span></span></div>
                </label>
                <label for="alert_invalid_phone" class="switch_option capsule_btn border-0 m-0">
                    <h5>Invalid phone</h5>
                    <input type="checkbox" :true-value="1" :false-value="0" id="alert_invalid_phone" v-model="alert_invalid_phone" hidden>
                    <div><span></span></div>
                </label>
            </div>
            <label v-if="1 == 0" for="filter_portal_logins" class="switch_option capsule_btn border-0 mb-1">
                <h5>Less than <input type="number" min="0" max="100" v-model="portal_logins_numb"> client portal logins </h5>
                <input type="checkbox" :true-value="1" :false-value="0" id="filter_portal_logins" v-model="filter_portal_logins" hidden>
                <div><span></span></div>
            </label>
            <label v-if="1 == 0" for="filter_unopen_email" class="switch_option capsule_btn border-0 mb-1">
                <h5>Has not opened email in <input type="number" min="0" max="100" v-model="not_open_days"> days </h5>
                <input type="checkbox" :true-value="1" :false-value="0" id="filter_unopen_email" v-model="filter_unopen_email" hidden>
                <div><span></span></div>
            </label>
            <label v-if="1 == 0" for="filter_email_open_rate" class="switch_option capsule_btn border-0 mb-1">
                <h5>Email open rate less than <input type="number" min="0" max="100" v-model="email_open_rate"> % </h5>
                <input type="checkbox" :true-value="1" :false-value="0" id="filter_email_open_rate" v-model="filter_email_open_rate" hidden>
                <div><span></span></div>
            </label>
        </div>
    </div>
</template>

<script>
    import { mapState, mapActions } from 'vuex'

    import constants from '@/utils/Constant'

    export default {
        name: 'Search Filter',

        data () {
            return {
                filter_name: 0,
                filter_email: 0,
                filter_phone: 0,
                filter_others: 0,
                filter_alerts: 0,
                firstName:{
                    mode: 'single',
                    value: [],
                    options: [
                        { value: 'Starts with', label: 'Starts with' },
                        { value: 'Ends with', label: 'Ends with' },
                        { value: 'Contains', label: 'Contains' },
                        { value: 'Is', label: 'Is' },
                        { value: 'Is Not', label: 'Is Not' },
                    ],
                },
                lastName: {
                    mode: 'single',
                    value: [],
                    options: [
                        { value: 'Starts with', label: 'Starts with' },
                        { value: 'Ends with', label: 'Ends with' },
                        { value: 'Contains', label: 'Contains' },
                        { value: 'Is', label: 'Is' },
                        { value: 'Is Not', label: 'Is Not' },
                    ],
                },
                email:{
                    mode: 'single',
                    value: [],
                    options: [
                        { value: 'Starts with', label: 'Starts with' },
                        { value: 'Ends with', label: 'Ends with' },
                        { value: 'Contains', label: 'Contains' },
                        { value: 'Is', label: 'Is' },
                        { value: 'Is Not', label: 'Is Not' },
                    ],
                },
                phone:{
                    mode: 'single',
                    value: [],
                    options: [
                        { value: 'Starts with', label: 'Starts with' },
                        { value: 'Ends with', label: 'Ends with' },
                        { value: 'Contains', label: 'Contains' },
                        { value: 'Is', label: 'Is' },
                        { value: 'Is Not', label: 'Is Not' },
                    ],
                },
                dateJoined: {
                    mode: 'single',
                    value: [],
                    options: [
                        { value: 'Date is', label: 'Date is' },
                        { value: 'Date is not', label: 'Date is not' },
                        { value: 'Date is before', label: 'Date is before' },
                        { value: 'Date is after', label: 'Date is after' }
                    ],
                },
                temperature:{
                    mode: 'single',
                    value: [],
                    options: [
                        { value: 'Cold', label: 'Cold' },
                        { value: 'Warm', label: 'Warm' },
                        { value: 'Hot', label: 'Hot' }
                    ],
                },
                phoneStatus:{
                    mode: 'single',
                    value: [],
                    options: [
                        { value: 'Invalid', label: 'Invalid' },
                        { value: 'Missing', label: 'Missing' }
                    ],
                },
                emailStatus:{
                    mode: 'single',
                    value: [],
                    options: [
                        { value: 'Invalid', label: 'Invalid' },
                        { value: 'Missing', label: 'Missing' }
                    ],
                },
                lastAction: {
                    mode: 'single',
                    value: [],
                    options: constants.last_actions,
                    searchable: true
                },
                flagged:{
                    mode: 'single',
                    value: [],
                    options: [
                        { value: 1, label: 'Is flagged' },
                        { value: 0, label: 'Is not flagged' }
                    ],
                },
                masterkey:{
                    mode: 'single',
                    value: [],
                    options: [
                        { value: 'Has masterkey', label: 'Has masterkey' },
                        { value: 'not masterkey', label: 'Does not have masterkey' }
                    ],
                },
                gender:{
                    mode: 'single',
                    value: [],
                    options: [
                        { value: 'Male', label: 'Male' },
                        { value: 'Female', label: 'Female' },
                        { value: 'Unassigned', label: 'Unassigned' },
                    ],
                },
                filters: {
                    first_name: {
                        filter: '',
                        keyword: '',
                    },
                    last_name: {
                        filter: '',
                        keyword: '',
                    },
                    date_joined: {
                        filter: '',
                        keyword: '',
                    },
                    email: {
                        filter: '',
                        keyword: '',
                    },
                    phone: {
                        filter: '',
                        keyword: '',
                    },
                    gender: '',
                    flagged: '',
                    tags: [],
                    temperature: '',
                    phone_status: '',
                    email_status: '',
                    status: '',
                    last_action: '',
                },
                alert_unsubscribed: 0,
                alert_bounced: 0,
                alert_spam: 0,
                alert_email_disabled: 0,
                alert_broadcasts_disabled: 0,
                alert_unconfirmed_email: 0,
                alert_invalid_email: 0,
                alert_missing_email: 0,
                alert_missing_phone: 0,
                alert_invalid_phone: 0,
                filter_portal_logins: 0,
                portal_logins_numb: 0,
                filter_unopen_email: 0,
                not_open_days: 0,
                filter_email_open_rate: 0,
                email_open_rate: 0
            };
        },

        computed: mapState({
            statusesGroup: state => state.contactModule.statusesGroup,
            tagsGroup: state => state.tagModule.tagsGroup,
            statusTabsLoader: state => state.contactModule.statusTabsLoader,
        }),

        mounted () {
            const vm = this;

            if (vm.tagsGroup.length == 0) {
                vm.getTags();
            }
        },

        methods: {
            ...mapActions({
                getTags: 'tagModule/getTags',
            }),

            resetFilter () {
                const vm = this;

                vm.filters = {
                    first_name: {
                        filter: '',
                        keyword: '',
                    },
                    last_name: {
                        filter: '',
                        keyword: '',
                    },
                    date_joined: {
                        filter: '',
                        keyword: '',
                    },
                    email: {
                        filter: '',
                        keyword: '',
                    },
                    phone: {
                        filter: '',
                        keyword: '',
                    },
                    tags: [],
                    temperature: '',
                    phone_status: '',
                    email_status: '',
                    status: '',
                    last_action: '',
                    gender: '',
                    flagged: '',
                };
            },
        },
    }
</script>

<style scoped>
.switch_option h5 input{
    width: 60px;
    height: 24px;
    min-height: 28px;
    padding-left: 10px;
    display: inline-block;
    margin: 0 5px;
    border: 1px solid #e9e9e9;
    border-radius: 3px;
    font-size: 13px;
}
</style>
